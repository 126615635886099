import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import {envConfig} from "../../../config.js"
import { actions as salesActions } from "./ducks/sales.duck";
import useQuery from "../../hooks/useQuery";
import moment from "moment";

import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";

import {
  Flex,
  Text,
  SimpleGrid,
  Box,
  Grid,
  GridItem,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";

import search from "../../design-system/icons/Search/search-normal.svg";
import calendarIcon from "../../design-system/icons/Essentials/calendar.svg";

import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { Paginator } from "../../components/Paginator/Paginator";
import SalesItemLoader from "../../components/SalesItem/SalesItemLoader";

import SalesItem from "../../components/SalesItem/SalesItem";

import { actions as sales } from "./ducks/sales.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";
import { COMPANY_ITEMS, PRO_TENANTS } from "../../constants/constants";

const SalesPage = ({
  listSales,
  approveSales,
  rejectSales,
  result,
  ...props
}) => {
  const query = useQuery();
  const tenant = query.get("tenant") ?? "tenant";
  const state = query.get("state");
  const search_param = query.get("search_param");

  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");
  const d = query.get("d");

  const history = useHistory();
  const { pathname } = useLocation();

  const salesState = useSelector((state) => state?.sales);
  const [remitNumber, setRemitNumber] = useState("");
  const [sellerComments, setSellerComments] = useState("");
  const [sales, setSales] = useState([]);

  const toast = useToast();
  const location = useLocation().pathname;
  useEffect(() => {
      salesActions.fillOrderList([]);

      listSales({
        state,
        page,
        perPage,
        from,
        to,
        tenant,
        search_param,
      });
  }, [state, page, perPage, from, to, tenant, search_param, listSales]);

  useEffect(() => {
    if (salesState?.hasError && salesState?.hasError.status === "fail") {
      toast({
        title: salesState?.hasError?.error,
        position: "top-right",
        status: "error",
        duration: undefined,
        isClosable: true,
      });
    }
  }, [salesState.hasError, toast]);

  useEffect(() => {
    if (salesState?.sales.length > 0) {
      setSales(salesState?.sales);
    } else {
      setSales([]);
    }
  }, [salesState.sales]);

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const handleApproveOrder = (item, itemsState) => {
    approveSales({
      item,
      items: itemsState,
      dispatch_note_number: remitNumber,
      tenant: tenant,
    });
  };

  const handleRejectOrder = (item, itemsState) => {
    rejectSales({ item, items: itemsState, tenant: tenant });
  };

  const handlePagination = () => {
    history.go(0);
  };

  function handleFillResult(state) {
    result(state);
  }

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment().add(-24, "M").format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment().add(-3, "M").format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment().add(-6, "M").format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment().add(-12, "M").format("YYYY-MM-DD");
    }

    item?.date &&
      history.push(
        `${pathname}?state=${(state === "null" || !state) ? "0" : state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}${search_param ? `&search_param=${search_param}` : ""}${tenant ? `&tenant=${tenant}` : ""}`,
      );
  };

  const handleChangeSearchParam = (value) => {
    const queryParams = new URLSearchParams(history.location.search);
    if (!queryParams.has("search_param")) {
      value.length > 2 && queryParams.append("search_param", value);
    } else {
      value.length < 3
        ? queryParams.delete("search_param")
        : queryParams.set("search_param", value);
    }

    const newUrl = `${pathname}?${queryParams.toString()}`;

    return history.push(newUrl);
  };

  return (
    <Grid
      minH={sales && sales.length > 0 ? "100vh" : "unset"}
      flexDir={"column"}
      py={"2.5rem"}
      bg={"brand.background"}
      maxW={"75rem"}
      w={"100%"}
      margin={"0 auto"}
      px={["1rem", "1rem", "1rem", ".5rem"]}
    /*{...props}*/
    >
      <Flex mb={"1.5rem"}>
        <Breadcrumb spacing="0.5rem" separator={<BekeuIcon src={arrowRight} />}>
          <BreadcrumbItem>
            <BreadcrumbLink
              onClick={() => history.push("/")}
              _hover={{ color: "brand.hover" }}
              fontFamily={"Open Sans"}
              fontWeight={400}
              fontSize={"0.875rem"}
            >
              Inicio
            </BreadcrumbLink>
          </BreadcrumbItem>
          {location.includes("sales") && (
            <BreadcrumbItem>
              <BreadcrumbLink
                color={location === "/sales/" && "brand.primary"}
                onClick={() => history.push("/sales/?page=1&per_page=20&tenant=tenant")}
                _hover={{ color: "brand.hover" }}
                fontFamily={"Open Sans"}
                fontWeight={400}
                fontSize={"0.875rem"}
              >
                Mis ventas
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>
      </Flex>

      <Grid
        templateColumns={["unset", "unset", "unset", "20% auto"]}
        alignItems="center"
        justifyContent="space-between"
      >
        <GridItem>
          <H5>Mis ventas</H5>
        </GridItem>
        <GridItem justifySelf={["center", "flex-end"]}>
          <AlertBekeu >
            {PRO_TENANTS.includes(tenant)
              ? "Recordá que las compras de BEKEU PRO deberás facturarlas y presentar en nombre de Bekeu."
              : "Recordá que una vez recibida tu venta debes enviar el pedido."}
          </AlertBekeu>
        </GridItem>
      </Grid>
      <Flex justifyContent={"flex-end"}>
        <SimpleGrid mt={"2rem"} minChildWidth="12.5rem" spacing={4} w={"100%"}>
          {/* COMPANY FILTER */}
          <Box>
            <MenuBekeu
                placeholder={'Cliente'}
              itemListPerView={
                COMPANY_ITEMS.length > 3 ? 4 : COMPANY_ITEMS.length
              }
              ml={"1.875rem"}
              items={COMPANY_ITEMS.sort((a, b) => a.name.localeCompare(b.name))}
            >
              {!tenant || tenant === "tenant"
                ? "Bekeu Pro"
                : tenant === "pampa"
                  ? "Pampa Energía"
                  : tenant === "vista"
                    ? "Vista Energy"
                    : "Bekeu Pro"}
            </MenuBekeu>
          </Box>
          <Box>
            <MenuBekeu
              ml={"1.875rem"}
              items={[
                { name: "Todas", state: "0" },
                { name: "Pendiente", state: "2" },
                { name: "Enviada", state: "2.1" },
                { name: "Reclamada", state: "5" },
                { name: "Cancelada", state: "3.2" },
                { name: "Confirmada", state: "4" },
              ]}
            >
              {(state === "" || state === "0")
                ? "Todas"
                : state === "1"
                  ? "Pendiente"
                  : state === "2"
                    ? "Pendiente"
                    : state === "2.1"
                      ? "Enviada"
                      : state === "3.2"
                        ? "Cancelada"
                        : state === "4"
                          ? "Confirmada"
                          : state === "5"
                            ? "Reclamada"
                            : "Estado"}
            </MenuBekeu> 
          </Box>

          {/* DATE FILTER */}
          <Box>
            <MenuBekeu
              height={"3.5rem"}
              leftIcon
              leftCustomIcon={calendarIcon}
              setMoment={setMoment}
              state={state}
              ml={"2.12rem"}
              items={[
                {
                  name: "Todas",
                  date: "Todas",
                  to: formattedDate,
                  d: 0,
                },
                {
                  name: "Este mes",
                  date: "Este mes",
                  to: formattedDate,
                  d: 1,
                },
                {
                  name: "Últimos 3 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 3,
                },
                {
                  name: "Últimos 6 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 6,
                },
                {
                  name: "Últimos 12 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 12,
                },
              ]}
            >
              {d
                ? d === "0"
                  ? "Todas"
                  : d === "1"
                    ? "Este mes"
                    : d === "3"
                      ? "Últimos 3 meses"
                      : d === "6"
                        ? "Últimos 6 meses"
                        : d === "12" && "Últimos 12 meses"
                : "Fecha"}
            </MenuBekeu>
          </Box>

          {/* SEARCH */}
          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}

          <InputBekeu
            placeholder={"Buscar"}
            bg={"white"}
            lefticon={<BekeuIcon src={search} mr={4} />}
            defaultValue={search_param}
            onChange={(e) => handleChangeSearchParam(e.target.value)}
          />
        </SimpleGrid>
      </Flex>
      {!salesState.loading ? (
        <>
          {sales && sales.length > 0 ? (
            sales.map((item, id, itemArr) => {
              return (
                <SalesItem
                  mt={"2rem"}
                  type={item.state}
                  key={id}
                  item={item}
                  state={item.state}
                  remitNumber={remitNumber}
                  handleApproveOrder={handleApproveOrder}
                  setRemitNumber={setRemitNumber}
                  handleRejectOrder={handleRejectOrder}
                  setSellerComments={setSellerComments}
                  sellerComments={sellerComments}
                  handlePagination={handlePagination}
                  itemArr={itemArr}
                  handleFillResult={handleFillResult}
                  tenant={tenant}
                />
              );
            })
          ) : (
            <Text mt={6} className="animate__animated animate__fadeIn">
              {!tenant ? "Selecciona un cliente para ver tus ventas " : "No se encontraron ventas "}
              {state === "1"
                ? "pendientes"
                : state === "2"
                  ? "pendientes"
                  : state === "2.1"
                    ? "pendientes"
                    : state === "3"
                      ? "rechazadas"
                      : state === "3.2"
                        ? "rechazadas"
                        : state === "4"
                          ? "finalizadas"
                          : state === "5"
                            ? "reclamadas"
                            : ""}
            </Text>
          )}
          {!salesState.loading &&
            salesState &&
            salesState.salesPagination.totalPages > 1 &&
            sales &&
            sales.length > 0 && (
              <Paginator
                totalItems={salesState.salesPagination.totalPages}
                perPage={5}
              />
            )}
        </>
      ) : (
        [1, 2].map((item, id) => (
          <SalesItemLoader item={item} key={id} mt={"2rem"} />
        ))
      )}
    </Grid>
  );
};

export default injectIntl(
  connect(null, { ...sales, ...product.actions })(SalesPage),
);
